import './style.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { signInUser } from "../../actions/auth";
import history from "../../config/history";

class SignIn extends Component {
  state = {
    email: '',
    password: ''
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  submitForm = e => {
    e.preventDefault()

    const { signInUser } = this.props

    const {
      email,
      password,
    } = this.state

    signInUser({ email, password })
    .then(history.push("/"))
    .catch((error) => console.log(error.response.data.errors))
  }

  render () {
    const { submitForm, handleChange } = this;
    const { email, password } = this.state;

    return (
      <div id="signin-container">
        <div className="login-side">
          <div className="signin-title">
            Log In
          </div>
          <form onSubmit={submitForm}>
            {/* Email */}
            <div className="form-group">
              <label className="form-label" htmlFor="email">Email</label>
              <input
                id="customer-email"
                name="email"
                className="form-control"
                value={email}
                onChange={handleChange}
                type="email"
              />
            </div>

            {/* Password */}
            <div className="form-group">
              <label className="form-label" htmlFor="password">Password</label>
              <input
                id="customer-password"
                name="password"
                className="form-control"
                value={password}
                onChange={handleChange}
                type="password"
              />
            </div>

            <div className="button-wrapper">
              <button className="login-button" type="submit">Log In</button>
            </div>
          </form>
        </div>

        <div className="sign-up-side">
          <div className="message">
            New here ?
          </div>
          <div className="signup-wrapper">
            <Link to="/signup" className="signup-button">Sign Up</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { signInUser },
)(SignIn)
