import "./style.scss";
import React from "react";
import Modal from 'react-modal';

import defaultImage from "../../assets/images/default-image.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addItemToCart } from "../../services/customerApi";
import * as alert from "../../actions/alert";
import * as icons from "../../assets/icons";

const modalStyle = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '80%',
      padding               : '0'
    }
}

class ProductForm extends React.Component {
  state = {
    quantitySelected: 1
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleAddToCart = () => {
    const product = this.props.product;
    const quantity = this.state.quantitySelected;

    const item = {
      product_id: product.id,
      quantity: quantity
    }

    addItemToCart(item)
    .then(alert.success("Item has been added to cart."))
    .catch((error) => {
      if (error.response) {
        alert.error(error.response.data.message)
      }
      else {
        alert.error('Something went wrong')
      }
    })
  }

  render() {
    const { closeModal, isModalOpen } = this.props;
    const { name, description, price, inventory, image } = this.props.product;
    const { quantitySelected } = this.state;

    return (
      <div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={modalStyle}
        >
          <div className="product">
            <div className="col-md-6 image-panel">
              {/* Image */}
              <div className="image-preview">
                <img src={image || defaultImage} alt="product"/>
              </div>
              <div className="image-selector">
              </div>
            </div>

            <div className="col-md-6 detail-panel">
              {/* Name */}
              <div className="product-name">
                <h3>{ name }</h3>
              </div>

              {/* Description */}
              <div className="product-descrption">
                <span>{ description }</span>
              </div>

              <div className="selector row">
                {/* Inventory */}
                <div className="quantity-selector col-md-2">
                  <input
                    type="number"
                    min="1"
                    max={inventory}
                    name="quantitySelected"
                    className="form-control input-field"
                    value={quantitySelected}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="product-quantity col-md-4">
                  <h5>In Stock: { inventory }</h5>
                </div>
              </div>

              {/* Price */}
              <div className="actions row">
                <div className="product-price col-md-3">
                  <h4>${price}</h4>
                </div>
                <div className="add-to-cart col-md-3">
                  <button className="btn btn-primary" onClick={this.handleAddToCart}>
                    <FontAwesomeIcon icon={icons.cart} />Add to Cart
                  </button>
                </div>
              </div>

            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ProductForm;
