import "./style.scss";
import React from "react";
import { Link } from "react-router-dom";

import { fetchCart, removeItemFromCart } from "../../services/customerApi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from "../../assets/icons";
import defaultImage from "../../assets/images/default-image.png";


class CheckoutOrderDetail extends React.Component {
  state = {
    isLoading: true,
    lineItems: [],
    totalItemsAmount: null,
    totalAmount: null
  }

  componentDidMount() {
    this._getCartFromApi();
  }

  // Api Calls

  _getCartFromApi = () => {
    fetchCart()
    .then((response) => {
      const cart = response.data;
      this.setState({
        lineItems: cart.line_items,
        totalItemsAmount: cart.total_items_amount,
        totalAmount: cart.total_amount,
        isLoading: false
      })
    })
  }

  // Handlers

  _handleDeleteLineItem = (lineItemId) => {
    removeItemFromCart(lineItemId)
    .then((response) => {
      const cart = response.data;

      this.setState({
        lineItems: cart.line_items,
        totalItemsAmount: cart.total_items_amount,
        totalAmount: cart.total_amount,
      })
    })
    .catch((error) => {
      if (error.response) {
        alert.error(error.response.data.message)
      }
      else {
        alert.error('Something went wrong')
      }
    })
  }

  // Renders

  renderLineItem = (lineItem, index) => {
    return(
      <div className="line-item row" key={index}>
        <div className="col-md-1 image">
          <img src={lineItem.image || defaultImage} alt={lineItem.product_name} />
        </div>

        <div className="col-md-2 name">
          <h5>{lineItem.product_name}</h5>
        </div>

        <div className="col-md-2 col-md-offset-3 quantity">
          <h5>Quantity: {lineItem.quantity}</h5>
        </div>

        <div className="col-md-3 price">
          <h5>Item cost: ${lineItem.price}</h5>
        </div>

        <div className="col-md-1 actions">
          <button
            className="delete-line-item btn btn-link"
            onClick={() => this._handleDeleteLineItem(lineItem.id)}
          >
            <FontAwesomeIcon icon={icons.remove} />
          </button>
        </div>
      </div>
    );
  }

  renderItemList = () => {
    const { lineItems } = this.state;

    return(
      <div className="order-item-list">
        {lineItems.map((lineItem, index) => (
          this.renderLineItem(lineItem, index)
        ))}
      </div>
    )
  }

  renderPrices = () => {
    const { totalAmount } = this.state;

    return(
      <div className="order-prices row">
        <div className="order-total-price col-md-2 col-md-offset-1">
          <h4>Total:</h4>
        </div>
        <div className="order-total-price col-md-2 col-md-offset-6">
          <h5>${totalAmount}</h5>
        </div>
      </div>
    );
  }

  renderActions = () => {
    const { nextStep } = this.props;

    return(
      <div className="order-actions">
        <button
          className="btn btn-primary next-btn"
          onClick={nextStep}
        >
          NEXT STEP <FontAwesomeIcon icon={icons.chevronRight} />
        </button>
        <Link to="/shop" className="btn btn-default">CONTINUE SHOPPING</Link>
      </div>
    );
  }

  render() {
    const { lineItems } = this.state;
    if (lineItems.length === 0) {
      return(
        <div className="text-center">
          <h4>Your order is empty.</h4>
          <Link to="/shop" className="btn btn-link btn-lg">Let's shop</Link>
        </div>
      );
    }
    else {
      return(
        <div className="checkout-order">
          {this.renderItemList()}
          {this.renderPrices()}
          {this.renderActions()}
        </div>
      );
    }
  }
}

export default CheckoutOrderDetail;
