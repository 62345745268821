import "./style.scss";
import React from "react";

import { Link, withRouter } from "react-router-dom";

import logo from "../../../../assets/images/logo-white.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { caretDown } from "../../../../assets/icons";

class Navbar extends React.Component {
  render() {
    return(
      <div id="public-navbar">
        <nav className={`navbar navbar-default`}>
          <div className="container-fluid">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div className="createy-log">
                <a className="navbar-brand" href="/">
                  <img src={logo} alt="createy"/>
                </a>
              </div>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <div className="row">
                {/* 1 of col-md is assigned to the headers */}
                <div className="col-md-offset-5 col-sm-offset-3">
                  <ul className="nav navbar-nav">
                    <li>
                      <Link
                        to='#'
                        className="btn btn-link"
                      >
                        Products <FontAwesomeIcon icon={caretDown} />
                      </Link>
                    </li>

                    <li>
                      <Link to='#' className="btn btn-link">Inspiration</Link>
                    </li>

                    <li>
                      <Link to='#' className="btn btn-link">Pricing</Link>
                    </li>
                  </ul>
                </div>

                <div className="right-links">
                  <ul className="nav navbar-nav">
                    <li>
                      <Link to='/signup' className="btn btn-signup">Sign Up</Link>
                    </li>

                    <li>
                      <Link to='/signin' className="btn btn-link">Log In</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default withRouter(Navbar);
