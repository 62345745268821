import React from "react";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "../../config/history";

import { SignIn, SignUp } from '../../screens';
import Navbar from "../../components/shared/public/navbar";

class PublicContainer extends React.Component {
  render() {
    return(
      <Router history={history}>
        <div className="public-container">
          <Navbar />
          <Switch>
            <Route
              path={"/"}
              exact={true}
              component={SignIn}
            />
            <Route
              path={"/signin"}
              component={SignIn}
            />
            <Route
              path={"/signup"}
              component={SignUp}
            />
            <Route
              render={() => <Redirect to="/"/>}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default PublicContainer;
