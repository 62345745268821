import "./style.scss";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { Link } from "react-router-dom";
import { fetchCart, processOrder, fetchCards, setOrderCard } from "../../services/customerApi";

import * as alert from "../../actions/alert";
import history from "../../config/history";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from "../../assets/icons";

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: "white",
    border: '1px solid #707070',
    borderRadius: '10px',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    overflow: 'auto',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6, 0),
    color: '#707070',
    textAlign: 'center',
  },
  icon: {
    fontSize: '5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
    color: '#03BD5A'
  }
}));

function SuccessfullOrderModal(props) {
  const classes = useStyles();
  const { open, onClose } = props;


  return (
    <div >
      <Modal
        aria-labelledby="successfull-order-title"
        aria-describedby="successfull-order-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.icon}>
              <FontAwesomeIcon icon={icons.checkCircle} />
            </div>
            <div>
              <h2>THANK YOU</h2>
            </div>
            <div>
              <h4>Order placed successfully.</h4>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}


class CheckoutPayment extends React.Component {
  state = {
    isLoading: true,
    card: { id: 0 },
    cards: [],
    totalItemAmount: null,
    totalAmount: null,
    isModalOpen: false
  }

  componentDidMount() {
    this._getCardFromApi();
    this._getCardsFromApi();
  }

  // Api Calls

  _getCardsFromApi = () => {
    fetchCards()
    .then((response) => {
      this.setState({
        cards: response.data,
        isLoading: false
      })
    })
  }

  _getCardFromApi = () => {
    fetchCart()
      .then((response) => {
        if(response.data){
          this.setState({
            card: response.data.card,
            totalItemAmount: response.data.total_items_amount,
            totalAmount: response.data.total_amount
          })
        }
      })
    .catch((error) => {
      if (error.response) {
        alert.error(error.response.data.message)
      }
      else {
        alert.error('Something went wrong')
      }
    })
  }
  
  _selectCardFromApi = (id) => {
    let params = new FormData();
    params.append('card_id', id);
    setOrderCard(params)
    .then((response) => {
      if(response) {
        this.setState({
          card: response.data.card,
        })
      }
    })
  }

  // Handlers

  openModal = () => {
    this.setState({
      isModalOpen: true
    })

    setTimeout(() => {
      this.handleModalClose();
    }, 5000);
  }

  handleCardChange = event => {
    this._selectCardFromApi(event.target.value)
  };

  _handlePay = () => {
    processOrder()
    .then((response) => {
      if(response.data.success){
        this.openModal();
      }
    })
    .catch((error) => {
      if (error.response) {
        alert.error(error.response.data.message)
      }
      else {
        alert.error('Something went wrong')
      }
    })
  }

  handleModalClose = () => {
    this.setState({
      isModalOpen: false
    })
    history.push("/")
  }

  // Renders

  renderActions = () => {
    const { backStep } = this.props;

    return(
      <div className="actions">
        <button
          className="btn btn-primary pay-btn"
          onClick={this._handlePay}
        >
          PLACE ORDER
        </button>

        <button
          className="btn btn-default"
          onClick={backStep}
        >
          BACK
        </button>
      </div>
    );
  }

  renderCardInfo = () => {
    const { card } = this.state;

    return(
      <div className="card-info text-center">
        <h4>Card Number: {card.number}</h4>
      </div>
    );
  }

  renderCardsList = () => {
    const { cards, card } = this.state;

    return(
      <div className="cards-list">
        <div>
          <h4 className="title">Cards</h4>
          <Link to='/user/settings' className="btn btn-primary">
            <FontAwesomeIcon icon={icons.add}></FontAwesomeIcon> Add New
          </Link>
        </div>

        <FormControl component="fieldset">
          <RadioGroup aria-label="position" name="card" value={card.id} onChange={this.handleCardChange} row>
            {cards.map((card, index) => (
              <FormControlLabel
                key={index}
                value={card.id}
                control={<Radio color="primary" />}
                label={card.last4}
                labelPlacement="end"
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }

  renderCard = () => {
    const { card } = this.state;

    return(
      <div className="card text-center">
        <div>
          <p>{card.brand}</p>
        </div>

        <div>
          <p>{card.country}</p>
        </div>

        <div>
          <h5>{card.last4}</h5>
        </div>

        <div>
          <h5>{card.funding}</h5>
        </div>

        <div className="row note">
          <h5 className="col-md-2">Expiry: </h5>
          <h6 className="col-md-9">{card.exp_month} {card.exp_year}</h6>
        </div>
      </div>
    );
  }

  renderAmounts = () => {
    const { totalItemAmount, totalAmount } = this.state;

    return(
      <div className="amounts text-center">
        <h5>Items Amount: {totalItemAmount}</h5>
        <h5>Total Amount: {totalAmount}</h5>
      </div>
    );
  }

  render() {
    const { cards, isModalOpen } = this.state;

    if (cards.length < 1) {
      return(
        <div className="text-center">
          <h4>Your have no card</h4>
          <Link to='/user/settings' className="btn btn-primary">
            <FontAwesomeIcon icon={icons.add}></FontAwesomeIcon> Add New
          </Link>
        </div>
      );
    }
    else {
      return(
        <div className="checkout-payment">
          {this.renderCardsList()}
          {this.renderCard()}
          {this.renderAmounts()}
          {this.renderActions()}
          <SuccessfullOrderModal
            open={isModalOpen}
            onClose={this.handleModalClose}
          />
        </div>
      );
    }
  }
}

export default CheckoutPayment;
