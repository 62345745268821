import "./style.scss";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { fetchAddresses, deleteAddress } from "../../services/customerApi";
import { UserAddressForm } from "../index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from "../../assets/icons";
import * as alert from "../../actions/alert";

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: "white",
    border: '2px solid #000',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    overflow: 'auto',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function AddAddressModal({fetchAddressesList}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button className="btn btn-primary" onClick={handleOpen}><FontAwesomeIcon icon={icons.add}/> Add Address</button>
      <Modal
        aria-labelledby="add-address-modal-title"
        aria-describedby="add-address-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <UserAddressForm
              closeModal={handleClose}
              onSuccess={fetchAddressesList}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

class UserAddresses extends React.Component {
  state = {
    isLoading: true,
    addresses: [],
    isFormModalOpen: false
  }

  componentDidMount() {
    this._getAddressesFromApi();
  }

  // Api Calls

  _getAddressesFromApi = () => {
    fetchAddresses()
    .then((response) => {
      this.setState({
        addresses: response.data,
        isLoading: false
      })
    })
  }


  // Handlers

  _handleDeleteAddress(address_id) {
    const {addresses} = this.state;
    deleteAddress(address_id)
    .then(() => {
      alert.success("Address has been removed");
      this.setState({
        addresses: addresses.filter(address => address.id !== address_id)
      })
    })
    .catch((error) => {
      if(error.response) {
        alert.error(error.response.data.message);
      }
      else {
        alert.error("Something went wrong");
      }
    })
  }

  // Renders

  renderAddresses = () => {
    const { addresses } = this.state;

    return(
      <div>
        <table className="table">
          <tr>
            <th>#</th>
            <th>Country</th>
            <th>City</th>
            <th>State</th>
            <th>Postal Code</th>
            <th>Line 1</th>
            <th>Line 2</th>
            <th>Note</th>
            <th>Delete</th>
          </tr>
          {addresses.map((address, index) => (
            <tr>
              <th>{index + 1}</th>
              <td>{address.country}</td>
              <td>{address.city}</td>
              <td>{address.state}</td>
              <td>{address.postal_code}</td>
              <td>{address.line1}</td>
              <td>{address.line2}</td>
              <td>{address.note}</td>
              <td>
                <button
                  className="delete-address btn btn-link"
                  onClick={() => this._handleDeleteAddress(address.id)}
                >
                  <FontAwesomeIcon icon={icons.remove} />
                </button>
              </td>
            </tr>
            ))
          }
        </table>
      </div>
    );
  }

  render() {
    return(
      <div>
        {this.renderAddresses()}
        <AddAddressModal fetchAddressesList={this._getAddressesFromApi} />
      </div>
    );
  }
}

export default UserAddresses;
