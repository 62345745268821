import './style.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from "../../config/history";
import { registerUser } from "../../actions/auth";

class SignUp extends Component {
  state = {
    email: '',
    password: '',
    subdomain: '',
    name: '',
    confirmPassword: ''
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  submitForm = (e) => {
    e.preventDefault()

    const { registerUser } = this.props

    const {
      email,
      subdomain,
      password,
      name
    } = this.state

    registerUser({ email, subdomain, password, name })
    .then(history.push("/"))
    .catch((error) => console.log(error.response.data.errors))
  }

  render () {
    return (
      <div>
        Make a registration form for customer here.
      </div>
    )
  }
}

export default connect(
  null,
  { registerUser },
)(SignUp)
