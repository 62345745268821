import "./style.scss";
import React from "react";

import { addAddress } from "../../services/customerApi";

import * as alert from "../../actions/alert";

class UserAddressForm extends React.Component {
  state = {
    city: "",
    country: "",
    line1: "",
    line2: "",
    state: "",
    postalCode: "",
    note: "",
    buttonDisabled: false
  }

  // Handlers

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      buttonDisabled: true
    });

    const { city, country, line1, line2, postalCode, note, state } = this.state;

    const address = {
      city,
      country,
      line1,
      line2,
      state,
      note,
      postal_code: postalCode
    }

    addAddress(address)
    .then((_response) => {
      alert.success("Shipping Address has been added.")
      this.props.closeModal();
      this.props.onSuccess();

      this.setState({
        buttonDisabled: false
      });  
    })
    .catch((error) => {
      if (error.response) {
        alert.error(error.response.data.message)
      }
      else {
        alert.error('Something went wrong')
      }

      this.setState({
        buttonDisabled: false
      });  
    })
  }

  // Renders

  render() {
    const { city, country, line1, line2, postalCode, note, state, buttonDisabled } = this.state;
    const { closeModal } = this.props;

    return(
      <div className="address-form">
        <div className="title text-center">
          <h4>Shipping Address</h4>
        </div>

        <form onSubmit={this.handleSubmit} className="product-form">
          <div className="row">
            {/* Line 1 */}
            <div className="form-group col-md-6">
              <textarea
               required={true}
                name="line1"
                placeholder="Address 1"
                className="form-control input-field"
                value={line1}
                onChange={this.handleChange}
              />
            </div>

            {/* Line 2 */}
            <div className="form-group col-md-6">
              <textarea
                name="line2"
                placeholder="Address 2"
                className="form-control input-field"
                value={line2}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row">
            {/* City */}
            <div className="form-group col-md-6">
              <input
                required={true}
                type="text"
                name="city"
                placeholder="City"
                className="form-control input-field"
                value={city}
                onChange={this.handleChange}
              />
            </div>

            {/* Country */}
            <div className="form-group col-md-6">
              <input
                required={true}
                type="text"
                name="country"
                placeholder="Country"
                className="form-control input-field"
                value={country}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row">
            {/* State */}
            <div className="form-group col-md-6">
              <input
                required={true}
                type="text"
                name="state"
                placeholder="State"
                className="form-control input-field"
                value={state}
                onChange={this.handleChange}
              />
            </div>

            {/* Postal Code */}
            <div className="form-group col-md-6">
              <input
                required={true}
                type="number"
                name="postalCode"
                placeholder="ZIP / Postal Code"
                className="form-control input-field"
                value={postalCode}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row">
            {/* Note */}
            <div className="form-group col-md-12">
              <textarea
                name="note"
                placeholder="Enter notes or any tips for address"
                className="form-control input-field"
                value={note}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <button type="submit" className="btn btn-primary" disabled={buttonDisabled}>Save</button>
          <button className="btn btn-default btn-cancel" onClick={closeModal}>Cancel</button>
        </form>
      </div>
    );
  }
}

export default UserAddressForm;
