import "./style.scss";
import React from "react";

import { ProductItem, Product } from "../index";

class Products extends React.Component {
  state = {
    isModalOpen: false,
    selectedProductId: null
	}

  closeProductModal = () => {
    this.setState({
      isModalOpen: false,
      selectedProductId: null
    })
  }

	openProductModal = (productId) => {
    this.setState({
      selectedProductId: productId,
      isModalOpen: true
    })
  }
  ////////// Rendering //////////

  renderList = () => {
		const { products, listView } = this.props;
    return(
      <div className="list">
        {products.map((product, index) => (
          <ProductItem
            product={product}
						key={index}
            viewProduct={this.openProductModal}
            listView={listView}
          />
        ))}
      </div>
    );
  }

  renderProductModal = () => {
    const product = this.props.products.find((product) => product.id === this.state.selectedProductId)

    return(
      product &&
      <Product
				product={product}
        isModalOpen={this.state.isModalOpen}
        closeModal={this.closeProductModal}
      />
    );
  }

  render() {
    return(
      <>
        <div className="product-list">
          {this.renderList()}
        </div>
        {this.renderProductModal()}
      </>
    );
  }
}


export default Products;
