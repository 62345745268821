import "./style.scss";
import React from "react";
import { Radio, RadioGroup, FormControlLabel, FormControl} from '@material-ui/core';
import { Link } from "react-router-dom";

import { fetchCart, fetchAddresses, setOrderAddress } from "../../services/customerApi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from "../../assets/icons";
import * as alert from "../../actions/alert";

class CheckoutShippingDetail extends React.Component {
  state = {
    isLoading: true,
    addresses: [],
    address: { id: 0 },
  }

  componentDidMount() {
    this._getAddressesFromApi();
    this._getOrderAddressFromApi();
  }

  // Api Calls

  _getAddressesFromApi = () => {
    fetchAddresses()
    .then((response) => {
      this.setState({
        addresses: response.data,
        isLoading: false
      })
    })
  }

  _getOrderAddressFromApi = () => {
    fetchCart()
    .then((response) => {
      this.setState({
        address: response.data.shipping_address,
      })
    })
  }

  _selectAddressFromApi = (id) => {
    let params = new FormData();
    params.append('address_id', id);
    setOrderAddress(params)
    .then((response) => {
      if(response) {
        this.setState({
          address: response.data.shipping_address,
        })
      }
    })
    .catch((error) => {
      if (error.response) {
        alert.error(error.response.data.message)
      }
      else {
        alert.error('Something went wrong')
      }
    })
  }

  // Handlers

  handleChange = event => {
    this._selectAddressFromApi(event.target.value)
  };

  _handleNextClick = () => {
    const { address } = this.state;
    const params = {
      address_id: address.id
    }

    setOrderAddress(params)
    .then((_response) => {
      this.props.nextStep();
    })
    .catch((error) => {
      if (error.response) {
        alert.error(error.response.data.message)
      }
      else {
        alert.error('Something went wrong')
      }
    })
 }

  // Renders

  renderActions = () => {
    const { backStep } = this.props;

    return(
      <div className="actions">
        <button
          className="btn btn-primary next-btn"
          onClick={this._handleNextClick}
        >
          NEXT STEP <FontAwesomeIcon icon={icons.chevronRight} />
        </button>

        <button
          className="btn btn-default"
          onClick={backStep}
        >
          BACK
        </button>
      </div>
    );
  }

  renderAddressList = () => {
    const { addresses, address } = this.state;

    return(
      <div className="shipping-address-list">
        <div>
          <h4 className="title">Shipping Address</h4>
          <Link to='/user/settings' className="btn btn-primary">
            <FontAwesomeIcon icon={icons.add}></FontAwesomeIcon> Add New
          </Link>
        </div>

        <FormControl component="fieldset">
          <RadioGroup aria-label="position" name="shipping_address" value={address.id} onChange={this.handleChange} row>
            {addresses.map((address, index) => (
              <FormControlLabel
                key={index}
                value={address.id}
                control={<Radio color="primary" />}
                label={address.city}
                labelPlacement="end"
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }

  renderAddress = () => {
    const { address } = this.state;

    return(
      <div className="shipping-address text-center">
        <div>
          <p>{address.line1}</p>
        </div>

        <div>
          <p>{address.line2}</p>
        </div>

        <div>
          <h5>{address.state}</h5>
        </div>

        <div>
          <h5>{address.city}</h5>
        </div>

        <div>
          <h5>{address.country}</h5>
        </div>

        <div>
          <h5>{address.postal_code}</h5>
        </div>

        <div className="row note">
          <h5 className="col-md-2">Note: </h5>
          <h6 className="col-md-9">{address.note}</h6>
        </div>
      </div>
    );
  }

  render() {
    const { addresses } = this.state;

    if (addresses.length < 1) {
      return(
         <div className="text-center">
          <h4>Your have no shipping address</h4>
          <Link to='/user/settings' className="btn btn-primary">
            <FontAwesomeIcon icon={icons.add}></FontAwesomeIcon> Add New
          </Link>
        </div>
      );
    }
    else {
      return(
        <div className="checkout-shipping">
          {this.renderAddressList()}
          {this.renderAddress()}
          {this.renderActions()}
        </div>
      );
    }
  }
}

export default CheckoutShippingDetail;
