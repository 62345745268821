import "./style.scss";
import React from "react";
import { connect } from 'react-redux';

import { CardElement, injectStripe } from 'react-stripe-elements';
import * as alert from "../../actions/alert";
import { addCard } from "../../services/customerApi";

class UserCardForm extends React.Component {
  state = {
    buttonDisabled: false
  }

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(_ev) {
    this.setState({
      buttonDisabled: true
    });

    const { email, closeModal, onSuccess } = this.props;

    this.props.stripe.createToken({ email: email }).then((response) => {
      let params = new FormData();
      params.append('secret_key', response.token.id);
      addCard(params).then((response) => {
        if (response) {
          alert.success("Card has been verified.");
          closeModal();
          onSuccess();
        }
        else {
          console.log(response);
        }

        this.setState({
          buttonDisabled: false
        });
      })
    })
  }

  render() {
    const { buttonDisabled } = this.state;

    return (
      <div className="card-form">
        <CardElement />
        <div className="submit-btn">
          <button
            className="btn btn-success"
            disabled={buttonDisabled}
            onClick={this.submit}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  email: state.reduxTokenAuth.currentUser.attributes.email,
})

export default connect(mapStateToProps, null)(injectStripe(UserCardForm));
