import './style.scss';
import React from "react";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "../../config/history";
import routes from "../../config/routes";
import { Navbar, Alert, Footer } from "../../components";

class CustomerContainer extends React.Component {
  render() {
    return(
      <Router history={history}>
        <div className="customer-container">
          <div>
            <Navbar />

            <Alert />

            <div className="main-page">
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.main}
                  />
                ))}

                <Route
                  render={() => <Redirect to="/"/>}
                />
              </Switch>
            </div>

            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default CustomerContainer;
