import "./style.scss";
import React from "react";

import routes from "../../config/routes";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from "../../assets/icons";

class Footer extends React.Component {
  render() {
    return(
      <footer className="page-footer font-small blue pt-4">
        <div className="container-fluid text-center text-md-left">
          <div className="row">
            <div className="col-md-3">
              <div className="createy-logo">
                <img src={logo} alt="createy"/>
                <h5 className="title">Title</h5>
              </div>
            </div>

            <div className="col-md-6 links">
              <ul className="nav navbar-nav">
                {routes.map((route, index) => (
                  route.showInFooter &&
                  <li
                    key={index}
                  >
                    <Link to={route.path} className="btn btn-link">{route.title}</Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-md-3 icons">
              <a className="social-icons" href="#!">
                <FontAwesomeIcon icon={icons.facebook} />
              </a>
              <a className="social-icons" href="#!">
                <FontAwesomeIcon icon={icons.twitter} />
              </a>
              <a className="social-icons" href="#!">
                <FontAwesomeIcon icon={icons.instagram} color="grey"/>
              </a>
            </div>
          </div>

        </div>

          <div className="footer-copyright text-center py-3">
            All rights reserved 2019
          </div>

      </footer>
    );
  }
}

export default Footer;
