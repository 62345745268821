import "./style.scss";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { Elements, StripeProvider } from 'react-stripe-elements';
import { STRIPE_PUBLIC_KEY } from "../../constants";
import { UserCardForm } from "../index";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from "../../assets/icons";
import { fetchCards, deleteCard } from "../../services/customerApi";
import * as alert from "../../actions/alert";

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: "white",
    border: '2px solid #000',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 0),
  },
}));

function AddCardModal({fetchCardsList}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button className="btn btn-primary" onClick={handleOpen}><FontAwesomeIcon icon={icons.add}/> Add Card</button>
      <Modal
        aria-labelledby="add-card-modal-title"
        aria-describedby="add-card-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
              <Elements>
                <UserCardForm
                  closeModal={handleClose}
                  onSuccess={fetchCardsList}
                />
              </Elements>
            </StripeProvider>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}


class UserCards extends React.Component {
  state = {
    cards: []
  }

  _getCardsFromApi = () => {
    fetchCards()
      .then((response) => {
        this.setState({
          cards: response.data
        })
      })
  }

  componentDidMount() {
    this._getCardsFromApi();
  }

  // Handlers

    _handleDeleteCard(card_id) {
      const {cards} = this.state;
      deleteCard(card_id)
      .then(() => {
        alert.success("Card has been removed");
        this.setState({
          cards: cards.filter(card => card.id !== card_id)
        })
      })
      .catch((error) => {
        if(error.response) {
          alert.error(error.response.data.message);
        }
        else {
          alert.error("Something went wrong");
        }
      })
    }
  // renders

  render_cards() {
    const { cards } = this.state;

    return(
      <div>
        <table className="table">
          <tr>
            <th>#</th>
            <th>Country</th>
            <th>Brand</th>
            <th>Exp Month</th>
            <th>Exp Year</th>
            <th>Last 4</th>
            <th>Funding</th>
            <th>Delete</th>
          </tr>
          {cards.map((card, index) => (
            <tr>
              <th>{index + 1}</th>
              <td>{card.country}</td>
              <td>{card.brand}</td>
              <td>{card.exp_month}</td>
              <td>{card.exp_year}</td>
              <td>{card.last4}</td>
              <td>{card.funding}</td>
              <td>
                <button
                  className="delete-card btn btn-link"
                  onClick={() => this._handleDeleteCard(card.id)}
                >
                  <FontAwesomeIcon icon={icons.remove} />
                </button>
              </td>
            </tr>
            ))
          }
        </table>
      </div>
    );
  }

  render() {
    const { cards } = this.state;
    return(
      <div className="user-cards">
        {cards.length > 0 && this.render_cards()}

        <AddCardModal fetchCardsList={this._getCardsFromApi} />
      </div>
    );
  }
}

export default UserCards;



