import './style.scss';
import React from "react";

class Contacts extends React.Component {
  render() {
    return(
      <div>
        Contact
      </div>
    );
  }
}

export default Contacts;
